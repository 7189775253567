<template>
  <div class="divider-navbar pt-3 pb-1" />
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.divider-navbar {
  height: 20px;
}
</style>
