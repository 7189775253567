<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto">
      <p>
        Susunan Pengurus IDI Cabang Jakarta Pusat<br />Masa Bakti 2019-2022<br /><br /><br />DEWAN
        PERTIMBANGAN<br />Ketua<br />Prof. Dr. dr. Ari Fahrial Syam, MMB, Sp.PD,
        K-GEH<br />Anggota<br />Prof. Dr. dr. Agus Purwadianto, DFM, SH, M.Si,
        Sp.F(K)<br />Prof. dr. Hj. Qomariyah RS, MS, PKK, AIFM<br />Dr. dr.
        Fathema Djan Rachmat, Sp.B, Sp.BTKV<br />Dr. Lies Dina Liastuti,
        Sp.JP(K), MARS<br /><br />KETUA<br />Dr.Haznim Fadhli,Sp.S<br />Wakil
        Ketua<br />Dr. Ahmad Jamaludin, M.Kes<br />Dr. Dien Kuntarty, MKM<br /><br />SEKRETARIS
        UMUM<br />Dr. Ekasakti Octohariyanto, Mpd, Ked<br />Wakil Seketaris<br />Dr.
        Aqsa Azhary Nur, MPH<br />Dr.Radietya Alvarabie<br /><br />BENDAHARA<br />Dr.
        Mulyaningsih<br />Wakil Bendahara<br />Dr.Ainil Masthura,Sp.Ak<br /><br />BIDANG
        ORGANISASI<br />Ketua<br />Dr. Mulyadi Tedjapranata DTM &amp; H, MTh,
        FIAS<br />Anggota<br />Dr. M Yanuar Fajar, Sp.P, FISR, MARS<br />Dr.Fenny
        Ramadhiana<br /><br />HUBUNGAN ANTAR LEMBAGA<br />Ketua<br />Dr.
        Muhammad Alkaff, Sp.PD<br />Anggota<br />Dr. Rosita Rivai<br />Dr.
        Achmad Dimas Putranto, MPH<br /><br />PUSAT DATA DAN INFORMASI<br />Ketua<br />Dr.
        Hario Baskoro, Ph.D<br />Anggota<br />Dr. Dwi Rendra Hadi<br /><br />PENERBITAN
        DATA DAN INFORMASI<br />Ketua<br />Dr. Raden Fariz Nurwidya, Sp.P,
        Ph.D<br />Anggota<br />Dr. Agus Rizal Ardy Hariandy Hamid, Sp.U(K),
        Ph.D<br />Dr. RR Prasetyanugraheni Kreshanti, Sp.BP-RE(K)<br />Dr. Qori
        Haly, Sp.BP-RE<br />Dr. Esthika Dewiasty, Sp.PD-KGer, MSc<br /><br />PENGABDIAN
        MASYARAKAT DAN PENANGULANGAN BENCANA<br />Ketua<br />Dr. dr. Indra
        Kusuma, M.Biomed<br />Anggota<br />Dr. Reyhan Eddy Yunus, Sp.Rad,
        M.Sc<br />Dr. Fani Rosianti Rosman Ambiar, Sp.PD<br />Dr.Vera Dolores
        Lukman<br />Dr.Handayani<br />Dr. Yuziana, M.Biomed<br /><br />BIDANG
        KESAJAHTERAAN ANGGOTA DAN PENGEMBANGAN PELAYANAN TINGKAT
        PERTAMA/PRIMER<br />Ketua<br />Dr. Sri Hindarjo<br />Anggota<br />Dr.
        Stevany Angelia<br />Dr. Bela Dirk<br />Dr. Andi Khomeini Takdir Haruni,
        Sp.PD<br /><br />BIDANG KESAJAHTERAAN ANGGOTA DAN PENGEMBANGAN PELAYANAN
        TINGKAT LANJUTAN<br />Ketua<br />Dr. Wahyu Widodo, Sp.OT (K)<br />Anggota<br />Kol.
        Dr. Achmad J Didy S, Sp.OT(K) Spine<br />Dr. M Kurniawan, Sp.S(K), M.Sc,
        FICA<br />Dr. Andrew Renato Nafarin, Sp.A<br /><br />BIRO HUKUM,
        PEMBINAAN, DAN PEMBELAAN ANGGOTA (BHP2A)<br />Ketua<br />Dr. Seno
        Purnomo<br />Anggota<br />Dr. Fitri Ambar Sari, Sp.F, MPH<br />Dr. Citra
        Sari Pratiwi<br />Dr. dr. Ade Firmansyah Sugiharto, Sp.F<br /><br />TIM
        PENGEMBANGAN &amp; PENDIDIKAN KEDOKTERAN BERKELANJUTAN ( Tim-P2KB)<br />Ketua<br />Dr.
        Jihan Syukriyah<br />Anggota<br />Dr. M Arief Qomarullah, M.H.Kes<br />Dr.
        Yulianto<br />Dr. Ilham Nurdin<br />Dr. Iyan Permana<br />Dr.Mahendra<br /><br />MAJELIS
        KODE ETIK KEDOKTERAN (MKEK)<br />Ketua<br />Prof. DR. dr. Budi wiweko,
        Sp.OG(K), MPH<br />Anggota<br />Dr. Pukovisa Prawirohardjo, Sp.S<br />Dr.
        Febriani Endiyarti, Sp.THT-KL<br />Dr. H. Muhammad Baharudin, Sp.OG,
        MARS<br />Dr. Tjetjep Dwidja Siswaja, Sp.F, SH<br />
      </p>
    </div>
  </div>
</template>

<script>
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
  },
};
</script>

<style></style>
